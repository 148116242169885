import { MaterialSelectController } from "./material-select.controller";
import { Bindings } from "booking_app/types";

export class MaterialSelect {
  controller: any = MaterialSelectController;
  bindings: any = {
    ngModel: Bindings.ONE_WAY,
    formId: Bindings.ONE_WAY,
    formAttribute: Bindings.ONE_WAY,
    choices: Bindings.ONE_WAY,
    label: Bindings.ONE_WAY,
    inputName: Bindings.ONE_WAY,
    errorMessage: Bindings.ONE_WAY,
    fullErrorMessage: Bindings.ONE_WAY,
    choicesAriaLabel: Bindings.ONE_WAY,
    choicesGroupAriaLabel: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
  };

  require: any = {
    ngModelController: "ngModel",
  };

  template: string = `
  <md-input-container id="material-{{ $ctrl.formId }}-container"
    class="{{ $ctrl.formId }}-container material-select"
    ng-class="{ 'md-input-invalid': !$ctrl.ngModel && ($ctrl.checkoutForm().$submitted),
    'md-input-has-value': $ctrl.ngModel && $ctrl.ngModel.id !== null,
    'has-value': $ctrl.selectIsOpen() } ">
    <label translate="{{ $ctrl.label }}"></label>
    <ui-select id="material-{{ $ctrl.formId }}-field"
      ng-model="$ctrl.ngModel"
      required=""
      theme="select2"
      ng-change="$ctrl.ngModelChange()"
      name="{{ $ctrl.inputName }}"
      ng-focus="$ctrl.addFocusClass()"
      tabindex="-1"
      uis-open-close="$ctrl.resetElement(isOpen)"
      search-aria-label="{{ $select.selected.text | translate }}"
      choices-aria-label="{{ $ctrl.choicesAriaLabel || $ctrl.label }}"
      choices-group-aria-label="{{ $ctrl.choicesGroupAriaLabel || 'Dropdown' }}"
      >
      <ui-select-match translate="{{ $select.selected.text }}"
        aria-label="{{ $select.selected.text | translate }}, {{ $ctrl.label }}"
      > </ui-select-match>
      <ui-select-choices repeat="choice.value as choice in $ctrl.choices">
        <span translate="{{ choice.text }}"> </span>
      </ui-select-choices>
    </ui-select>
    <div class="md-errors-spacer"></div>
    <div ng-if="!$ctrl.ngModel && $ctrl.checkoutForm().$submitted"
      role="alert"
      class="md-input-messages-animation ng-active">
      <div class="md-input-message-animation"
        ng-if="$ctrl.fullErrorMessage"
        translate="Please select your title" class="md-input-message-animation">
      </div>
      <div class="md-input-message-animation"
        ng-if="$ctrl.errorMessage"
        translate="accounts.error.required.dropdown"
        translate-values="{ field: '{{$ctrl.errorMessage | translate | lowercase}}' }">
      </div>
    </div>
  </md-input-container>
  `;
}

angular.module("BookingApp").component("materialSelect", new MaterialSelect());
