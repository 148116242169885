import { UiSelectChoices } from "booking_app/types/ui-select-choices";
import { CheckoutFormService } from "booking_app/services/checkout/checkout-form.service";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { TravelType } from "booking_app/types";

export class MaterialSelectController {
  static $inject = ["CheckoutFormService", "GlobalStateService", "$timeout"];

  public choices: UiSelectChoices[];
  public inputName: string;

  private ngModelController: any;
  private ngModel: any;
  private formId: string;
  private formAttribute: any;
  private onUpdate: (obj: { value: any }) => void;

  constructor(
    private checkoutFormService: CheckoutFormService,
    private globalStateService: GlobalStateService,
    private $timeout: any,
  ) { }

  $onInit(): void {
    if (this.ngModel) {
      this.toggleMaterialContainerClass();
    }

    if (!this.ngModel && this.checkoutForm().$submitted) {
      this.$timeout(() => this.toggleInputInvalidClass(), 300);
    }
  }

  public ngModelChange(): void {
    this.processModelValue();
    this.ngModelController.$setViewValue(this.ngModel);

    if (this.onUpdate) {
      this.onUpdate({ value: this.ngModel });
    }

    this.toggleMaterialContainerClass(50);
  }

  public checkoutForm(): any {
    switch (this.globalStateService.travelType) {
      case TravelType.HOTELS:
        return this.checkoutFormService.checkoutFormScope.checkOutForm;
      case TravelType.FLIGHTS:
        return this.formAttribute || {};
      case TravelType.CARS:
        return this.checkoutFormService.checkoutFormScope.checkOutForm;
    }
  }

  public addFocusClass(): void {
    this.$timeout(() => {
      this.materialContainer().classList.add("md-input-focused");
    }, 300);
  }

  public resetElement(): void {
    if (this.ngModel) {
      this.toggleMaterialContainerClass(1);
    }
  }

  public selectIsOpen(): boolean {
    return this.materialContainer().getElementsByClassName("open").length > 0;
  }

  private toggleMaterialContainerClass(timeout: number = 300): void {
    this.$timeout(() => {
      this.materialContainer().classList.add("md-input-has-value");
    }, timeout);
  }

  private toggleInputInvalidClass(): void {
    this.materialContainer().classList.add("md-input-invalid");
  }

  private materialContainer(): any {
    return document.getElementById(`material-${this.formId}-container`);
  }

  private processModelValue(): any {
    switch (this.globalStateService.travelType) {
      case TravelType.FLIGHTS:
        this.ngModel = { value: this.ngModel, text: this.ngModel };
        break;
    }

    if (typeof this.ngModel.value === "number") {
      this.processIntModelValue();
    }
  }

  private processIntModelValue(): void {
    const modelValue: string =
      this.ngModel.value >= 10
        ? `${this.ngModel.value}`
        : `0${this.ngModel.value}`;
    this.ngModel.value = modelValue;
    this.ngModel.text = modelValue;
  }
}
