import { UserAgentChecker } from "booking_app/utils/user-agent-checker";
export class MaterialCheckboxController {

  static $inject = ["UserAgentChecker"];

  private ngModelController: any;
  private ngModel: any;

  constructor(private userAgentChecker: UserAgentChecker) {}

  internetExplorerChecker(): void {
    if (this.userAgentChecker.isIE()) {
      this.ngModelController.$setViewValue(!this.ngModel);
    }
  }
}
